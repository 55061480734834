@import "./../../colors";

$brand-primary: $primary;
$white: #fff;
$grey-light: rgb(200, 200, 200);
%remain-steps {
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 500;
    // font-size: 1.5rem;

    // @media screen and (max-width: 760px) {
    //   font-size: 1.2rem;
    // }
  }
  &:after {
    background-color: $grey-light;
  }
}
.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100px;

  * {
    transition: all 250ms ease-in-out;
  }

  > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    // color: $brand-primary;
    color: $white;

    .step-title {
      color: $grey-light;
    }

    &.c-pointer {
      cursor: pointer;
    }

    &:before {
      transition: all 250ms ease-in-out;
      content: "\f00c";
      content: "\2713;";
      content: "\10003";
      content: "\10004";
      content: "\2713";
      display: block;
      margin: 0 auto 4px;
      background-color: $primary;
      width: 100%;
      height: 60px;
      line-height: 55px;
      text-align: center;
      font-weight: bold;
      font-size: 1.6rem;

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: -10px;
      top: 0px;
      border-top: 30px solid $primary;
      border-bottom: 30px solid $primary;
      border-left: 21px solid $primary;
      background: white;
      z-index: 3;
      transition: all 250ms ease-in-out;
      // @media screen and (max-width: 760px) {
      // }
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

    &.is-active {
      @extend %remain-steps;
      &:after {
        border-left-color: $primary;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
      &:before {
        // transform: scale(1.1);
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
      .step-title {
        color: $primary;
      }

      ~ li {
        @extend %remain-steps;
        color: $grey-light;
        &:after {
          border-left-color: $grey-light;
          border-top-color: transparent;
          border-bottom-color: transparent;
        }

        &:before {
          background-color: $grey-light;
          border-color: $grey-light;
          color: $white;
        }
      }
    }
  }
}

.step-title {
  @media screen and (max-width: 760px) {
    display: none;
  }
}

.header-step-title {
  display: none;
  @media screen and (max-width: 760px) {
    display: block;
  }
}
