$enable-rounded: false;
$primary: #de2920;

.hover-button {
  &:hover {
    background: $primary !important;
    color: white;

    img {
      filter: brightness(100);
    }
  }
}

h5.primary {
  color: $primary;
}
